import React, { useState } from 'react';
import {
  Link,
  useNavigation,
} from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { login } from '../api';
import Logo from '../logo';

export default function Login() {
  const navigation = useNavigation();
  const [email, updateEmail] = useState('');
  const [password, updatePassword] = useState('');
  const [errorMessage, updateErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const doLogin = async (event) => {
    event.preventDefault();
    updateErrorMessage('');
    setIsLoading(true);
    const response = await login(email, password);
    setIsLoading(false);
    if (response.errorCode) {
      updateErrorMessage(response.message);
    } else {
      window.location.href = '/';
    }
  }

  return (
    <div
      id="login"
      className={navigation.state === "loading" ? "loading" : ""}
    >
      <Logo />
      <div className='login-card card inset'>
        <form onSubmit={doLogin}>
          { errorMessage ? (
            <div className='status error'>
              {errorMessage}
            </div>
          ) : null}
          <input placeholder='Email' type='email' value={email} onChange={(event) => updateEmail(event.target.value)} autoComplete='email'/>
          <input placeholder='Password' type='password' value={password} onChange={(event) => updatePassword(event.target.value)} autoComplete='current-password' />
          <button className='primary' type='submit' disabled={isLoading}>
            Log In
            { isLoading ? (
              <PulseLoader size={10} color='#b3aea8' className='loader' />
            ) : null }
          </button>
        </form>
        <div className='text-centered login-link'>
          <Link to='/forgotPassword' className='link'>
            Forgot password?
          </Link>
        </div>
        <div className='text-divider'>
          <span>or</span>
        </div>
        <Link to='/createAccount' className='link'>
          <button className='secondary' type='button'>
            Create Account
          </button>
        </Link>
      </div>
    </div>
  );
}