import React from 'react';
import {
  Link,
} from 'react-router-dom';
import Logo from '../logo';

export default function NotFound() {

  return (
    <div className='notFound'>
      <Logo />
      <div style={{textAlign: 'center'}}>
        <h1>
          404
        </h1>
        <p>
          It may not be warranted, but there's nothing to see here.
        </p>
        <p>
          <Link to='/'>
            <button className='primary btn'>Home</button>
          </Link>
        </p>
      </div>
    </div>
  );
}