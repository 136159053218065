import React, { useEffect, useState } from 'react';
import {
  Link,
  useNavigate,
  useNavigation,
} from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import { acceptInvite } from '../api';
import Logo from '../logo';
import CreateAccount from './createAccount';

export default function AcceptInvite() {
  const navigation = useNavigation();
  const navigate = useNavigate();
  const [errorMessage, updateErrorMessage] = useState('');
  const [successMessage, updateSuccessMessage] = useState('');
  const [needsRegistration, setNeedsRegistration] = useState(false);
  const [email, setEmail] = useState('');

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');

  useEffect(() => {
    const doAcceptInvite = async () => {
      const response = await acceptInvite(code);
      if (response.errorCode) {
        updateErrorMessage(response.message);
        return;
      }
      if (!response.needsRegistration) {
        updateSuccessMessage(response.message);
        window.location.href = '/';
      } else {
        setNeedsRegistration(true);
        setEmail(response.email);
      }
    }
    if (code) {
      doAcceptInvite();
    }
  }, [code]);

  if (!code) {
    return navigate('/');
  }

  if (needsRegistration) {
    return (
      <CreateAccount code={code} email={email} />
    );
  }

  return (
    <div
      id="login"
      className={navigation.state === "loading" ? "loading" : ""}
    >
      <Logo />
      <div className='login-card card'>
        { errorMessage ? (
          <>
            <div className='status error'>
              {errorMessage}
            </div>
            <div className='text-centered login-link'>
            <Link to='/' className='link'>
              Go to Login
            </Link>
        </div>
          </>
        ) : successMessage ? (
          <>
            <div className='status success'>
              {successMessage}
            </div>
            <div className='text-centered loading-icon'>
              <BounceLoader size={20} color="#36d7b7" />
            </div>
          </>
        ) : (
          <>
            <div className='description'>
              Checking invitation...
            </div>
            <div className='text-centered loading-icon'>
              <BounceLoader size={20} color="#36d7b7" />
            </div>
          </>
        )}
      </div>
    </div>
  );
}