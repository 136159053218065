import React from 'react';

export default function Logo() {

  return (
    <div className='logo'>
      <span className='logo-w'>
      <img src='/images/logo.svg' alt='W' />
      </span>
      arranted
    </div>
  );
}