import React, { useEffect, useState } from 'react';
import {
  Link,
  useNavigate,
  useNavigation,
} from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import { confirmEmail } from '../api';
import Logo from '../logo';

export default function ConfirmEmail() {
  const navigation = useNavigation();
  const navigate = useNavigate();
  const [errorMessage, updateErrorMessage] = useState('');
  const [successMessage, updateSuccessMessage] = useState('');

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const email = urlParams.get('email');

  useEffect(() => {
    const doConfirmEmail = async () => {
      const response = await confirmEmail(email, code);
      if (response.errorCode) {
        updateErrorMessage(response.message);
      } else {
        updateSuccessMessage(response.message);
        window.location.href = '/';
      }
    }
    if (code && email) {
      doConfirmEmail();
    }
  }, [code, email]);

  if (!code || !email) {
    return navigate('/');
  }

  return (
    <div
      id="login"
      className={navigation.state === "loading" ? "loading" : ""}
    >
      <Logo />
      <div className='login-card card'>
        { errorMessage ? (
          <>
            <div className='status error'>
              {errorMessage}
            </div>
            <div className='text-centered login-link'>
            <Link to='/' className='link'>
              Go to Login
            </Link>
        </div>
          </>
        ) : successMessage ? (
          <>
            <div className='status success'>
              {successMessage}
            </div>
            <div className='text-centered loading-icon'>
              <BounceLoader size={20} color="#36d7b7" />
            </div>
          </>
        ) : (
          <>
            <div className='description'>
              Confirming email address...
            </div>
            <div className='text-centered loading-icon'>
              <BounceLoader size={20} color="#36d7b7" />
            </div>
          </>
        )}
      </div>
    </div>
  );
}